<template>
  <div class="min-vh-100 section-bg-1 position-relative">
    <div class="container pb-65">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5 mt-90">
            <img src="/img/logo/logo-yellow.png" alt="Logo" width="400">
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6">

          <router-view />

          <div class="mt-5 text-center">
            <a href="https://sunnysouthvet.com"><small>Return to SSVS Website</small></a>
          </div>

        </div>
      </div>
    </div>

    <div class="position-absolute bottom-0 py-3 w-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                © 2022 Sunny South Veterinary Services
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>
