<template>
  <div v-if="$route.path !== '/' && !$route.meta.hideBreadcrumbs" class="ltn__breadcrumb-area text-left">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ltn__breadcrumb-inner">
            <h1 class="page-title">
              {{ pageTitle }}
            </h1>

            <div class="ltn__breadcrumb-list">
              <ul>
                <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
                  <router-link :to="breadcrumb.path" :custom="breadcrumb.path === $route.path">
                    <span v-if="breadcrumb.path === '/'" class="ltn__secondary-color"><i class="fa fa-home" /></span> <span>{{ breadcrumb.name }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useCategoryStore } from '@/stores/category'
import { useProductStore } from '@/stores/product'

export default {
  name: 'AppBreadcrumb',
  computed: {
    routes () {
      return this.$route.matched.filter((route) => route.name || route.meta.label)
    },

    ...mapState(useCategoryStore, ['CurrentCategory']),

    ...mapState(useProductStore, ['CurrentProduct']),

    pageTitle () {
      // set title as route name or if undefined then use name of parent
      let title = this.$route.name !== undefined ? this.$route.name : this.$route.matched?.at(-2)?.name

      if (this.isRoute('/browse') && this.CurrentCategory) {
        title = this.CurrentCategory.attributes.name
      } else if (this.isRoute('/search')) {
        title = `${this.$route.name} "${this.$route.query.search}"`
      } else if (this.isRoute('/products') && this.CurrentProduct) {
        title = this.CurrentProduct.attributes.name
      }

      return title
    },

    breadcrumbs () {
      const breadcrumbsArray = this.routes.map((e) => {
        return {
          path: e.path,
          name: e.name
        }
      })

      const categoryIds = this.$route.params.categoryId
      /** if browse products screen then add breadcrumbs for child categories  */
      if (this.isRoute('/browse') && this.CurrentCategory && categoryIds) {
        if (this.CurrentCategory.relationships && this.CurrentCategory.relationships.productcategory) {
          for (const [index, category] of this.CurrentCategory.relationships.productcategory.data.entries()) {
            breadcrumbsArray.push({
              path: '/browse/' + categoryIds.slice(0, index + 1).join('/'),
              name: category.attributes.name
            })
          }
        }

        breadcrumbsArray.push({
          path: '/browse/' + categoryIds.join('/'),
          name: this.CurrentCategory.attributes.name
        })
      } else if (this.isRoute('/products') && this.CurrentCategory && this.CurrentProduct) {
        let categoryIds = [this.CurrentCategory.id]
        if (this.CurrentCategory.relationships && this.CurrentCategory.relationships.productcategory) {
          const parentCategoryIds = this.CurrentCategory.relationships.productcategory.data.map(e => e.id)
          categoryIds = parentCategoryIds.concat(categoryIds)

          // Parent Category
          for (const [index, category] of this.CurrentCategory.relationships.productcategory.data.entries()) {
            breadcrumbsArray.push({
              path: '/browse/' + categoryIds.slice(0, index + 1).join('/'),
              name: category.attributes.name
            })
          }
        }

        // Product Category
        breadcrumbsArray.push({
          path: '/browse/' + categoryIds.join('/'),
          name: this.CurrentCategory.attributes.name
        })

        // Product
        breadcrumbsArray.push({
          path: '/products/' + this.CurrentProduct.id,
          name: this.CurrentProduct.attributes.name
        })
      }

      return breadcrumbsArray
    }
  },

  methods: {
    isRoute (checkPath) {
      return this.$route.matched.some(({ path }) => path === checkPath)
    }
  }
}
</script>
