<template>
  <footer class="ltn__footer-area  ">
    <div class="footer-top-area  section-bg-2 plr--5">
      <div class="container">
        <h3 class="text-center mb-5">
          Our Locations
        </h3>
        <div class="row justify-content-center">

          <div class="col-xl-4 col-md-4 col-12">
            <div class="footer-widget footer-menu-widget clearfix">
              <h4 class="footer-title">
                Lethbridge - Sunny South Vet (Main Office)
              </h4>

              <div class="footer-address">
                <ul>
                  <li>
                    <div class="footer-address-icon">
                      <i class="fa fa-phone" />
                    </div>
                    <div class="footer-address-info">
                      <p><a href="tel:1-877-588-9494">1-877-588-9494</a></p>
                    </div>
                  </li>
                  <li>
                    <div class="footer-address-icon">
                      <i class="fa fa-location-dot" />
                    </div>
                    <div class="footer-address-info">
                      <p>1118 - 41st Street N., Lethbridge AB</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 col-12">
            <div class="footer-widget footer-menu-widget clearfix">
              <h4 class="footer-title">
                Lacombe - Lacombe Vet Centre
              </h4>

              <div class="footer-address">
                <ul>
                  <li>
                    <div class="footer-address-icon">
                      <i class="fa fa-phone" />
                    </div>
                    <div class="footer-address-info">
                      <p><a href="tel:403-786-9103">403-786-9103</a></p>
                    </div>
                  </li>
                  <li>
                    <div class="footer-address-icon">
                      <i class="fa fa-location-dot" />
                    </div>
                    <div class="footer-address-info">
                      <p>5828 AB-2A, Lacombe, AB T4L 2G</p>
                    </div>
                  </li>
                  <li>
                    <div class="footer-address-info">
                      <p>Drs. Peter Lawson & Skylar Bieleny</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 col-12">
            <div class="footer-widget footer-menu-widget clearfix">
              <h4 class="footer-title">
                Medicine Hat - Badlands Veterinary Services
              </h4>

              <div class="footer-address">
                <ul>
                  <li>
                    <div class="footer-address-icon">
                      <i class="fa fa-phone" />
                    </div>
                    <div class="footer-address-info">
                      <p><a href="tel:403-878-4545">403-878-4545</a></p>
                    </div>
                  </li>
                  <li>
                    <div class="footer-address-icon">
                      <i class="fa fa-location-dot" />
                    </div>
                    <div class="footer-address-info">
                      <p>770-B 15 ST SW, Medicine Hat, AB</p>
                    </div>
                  </li>
                  <li>
                    <div class="footer-address-info">
                      <p>Dr. Krista Dayman</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
      <div class="container ltn__border-top-2">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="ltn__copyright-design clearfix">
              <p>© 2022 Sunny South Veterinary Services</p>
            </div>
          </div>
          <div class="col-md-6 col-12 align-self-center">
            <div class="ltn__copyright-menu text-end">
              <ul>
                <li><a href="#">Terms & Conditions</a></li>
                <li><a href="#">Privacy & Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
