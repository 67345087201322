<template>
  <div>
    <TheHeader />

    <div v-if="user && Boolean(Number(user.customer_on_hold))" class="alert alert-danger py-2 border-0 rounded-0 mb-0" role="alert">
      <div class="container">
        <i class="fa fa-triangle-exclamation me-1" />
        <small>Your account has been put on hold, please contact our office at <a href="tel:+14033272425">403-327-2425</a></small>
      </div>
    </div>

    <AppBreadcrumb />

    <router-view />

    <TheFooter />
  </div>

  <AppModal
    id="userChangedModal"
    ref="userChangedModal"
    hide-button
    no-close
  >
    <template #title>
      <i class="fa-regular fa-circle-info text-danger me-2" />
      <span class="text-danger">Log-in Change Detected</span>
    </template>

    <p class="mb-0">
      The logged in user has changed. Please refresh the page, or close the tab.
    </p>

    <template #footer>
      <button
        type="button"
        class="btn theme-btn-2 btn-effect-1 py-2 px-3"
        @click="$router.go(0)"
      >
        Refresh
      </button>
    </template>
  </AppModal>
</template>

<script setup>
import TheHeader from '@/components/TheHeader.vue'
import AppBreadcrumb from '@/components/app/AppBreadcrumb'
import TheFooter from '@/components/TheFooter.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import AppModal from '@/components/app/AppModal'

const userChangedModal = ref(null)

const authStore = useAuthStore()
const { accessToken, user } = storeToRefs(authStore)

watch(accessToken, (newVal, oldVal) => {
  if (newVal && oldVal) {
    userChangedModal.value.open()
  }
})
</script>
