import { defineStore } from 'pinia'

export const useProductStore = defineStore('product', {
  state: () => {
    return {
      CurrentProduct: null
    }
  },

  getters: {
    getCurrentProduct: state => state.CurrentProduct
  },

  actions: {
    setCurrentProduct (product) {
      this.CurrentProduct = product
    }
  }
})
