import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

import DefaultLayout from '@/layouts/DefaultLayout'
import AuthLayout from '@/layouts/AuthLayout'
import ChildLayout from '@/layouts/ChildLayout'

const ThePage404 = () => import('@/pages/misc/ThePage404')
const ComingSoon = () => import('@/pages/misc/ComingSoon')

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    component: ThePage404
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/pages/TheDashboard'),
        meta: { requiresAuth: true }
      },
      {
        path: 'browse',
        name: 'Browse Products',
        component: () => import('@/pages/product/BrowseProducts'),
        meta: { requiresAuth: true },
        children: [
          {
            path: ':categoryId+',
            name: null,
            component: () => import('@/pages/product/BrowseProducts'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'products',
        name: 'Products',
        redirect: '/browse',
        component: () => import('@/pages/product/ProductDetail'),
        meta: { requiresAuth: true },
        children: [
          {
            path: ':productId',
            name: null,
            component: () => import('@/pages/product/ProductDetail'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'on-sale',
        name: 'On Sale',
        component: () => import('@/pages/OnSale'),
        meta: { requiresAuth: true }
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/pages/product/ProductSearch'),
        meta: { requiresAuth: true }
      },
      {
        path: 'prescriptions',
        name: 'Current Prescriptions',
        component: () => import('@/pages/MyPrescriptions'),
        meta: { requiresAuth: true }
      },
      {
        path: 'protocols',
        name: 'My Protocols',
        component: () => import('@/pages/MyProtocols'),
        meta: { requiresAuth: true }
      },
      {
        path: 'favorites',
        name: 'Manage Favorites',
        component: () => import('@/pages/ManageFavorites'),
        meta: { requiresAuth: true }
      },
      {
        path: 'shop',
        name: 'Shop',
        redirect: '/shop/cart',
        component: () => import('@/pages/shop/ShopIndex'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'cart',
            name: 'Cart',
            component: () => import('@/pages/shop/ShopCart'),
            meta: { requiresAuth: true }
          },
          {
            path: 'checkout',
            name: 'Checkout',
            component: () => import('@/pages/shop/ShopCheckout'),
            meta: { requiresAuth: true, hideBreadcrumbs: true }
          },
          {
            path: 'order-confirmation',
            name: 'Order Confirmation',
            component: () => import('@/pages/shop/ShopOrderConfirmation'),
            props: true,
            meta: { requiresAuth: true, hideBreadcrumbs: true }
          }
        ]
      },
      {
        path: 'orders',
        name: 'Orders',
        redirect: '/orders/history',
        component: ChildLayout,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'history',
            name: 'Order History',
            component: () => import('@/pages/order/OrderHistory'),
            meta: { requiresAuth: true }
          },
          {
            path: ':orderId',
            name: 'Order Detail',
            component: () => import('@/pages/order/OrderDetail'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'saved-orders',
        name: 'Saved Orders',
        component: ChildLayout,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            component: () => import('@/pages/savedorder/SavedOrder'),
            meta: { requiresAuth: true }
          },
          {
            path: ':cartId',
            name: 'Cart Detail',
            component: () => import('@/pages/savedorder/SavedOrderDetail'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'account',
        name: 'My Account',
        component: () => import('@/pages/account/AccountIndex'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'Account Details',
            component: () => import('@/pages/account/AccountDetail'),
            meta: { requiresAuth: true }
          },
          {
            path: 'edit',
            name: 'Edit Account Details',
            component: () => import('@/pages/account/AccountEditDetails'),
            meta: { requiresAuth: true }
          },
          {
            path: 'change-password',
            name: 'Change Password',
            component: () => import('@/pages/account/AccountChangePassword'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'coming-soon',
        name: 'Coming Soon',
        component: ComingSoon,
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/',
    name: 'Auth',
    component: AuthLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/auth/LogIn'),
        meta: { requiresAuth: false }
      },
      {
        path: '/login-as/:token',
        name: 'Login As',
        component: () => import('@/pages/auth/TokenLogin'),
        meta: { requiresAuth: false }
      },
      {
        path: '/passwordreset',
        name: 'Password Reset',
        component: () => import('@/pages/auth/PasswordReset'),
        meta: { requiresAuth: false }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async (to, next) => {
  const authStore = useAuthStore()

  if (to.meta.requiresAuth) {
    try {
      await authStore.AUTH_CHECK_LOGIN()
    } catch (e) {
      return '/login'
    }
  }
  document.title = `${to.name ? `${to.name} | ` : ''}SSVS`
})

export default router
