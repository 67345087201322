import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import _isArray from 'lodash/isArray'

export const useCartStore = defineStore('cart', {
  state: () => {
    return {
      cart: useStorage('cart', [])
    }
  },

  getters: {
    getCartItems: (state) => {
      return state.cart.map((item) => {
        let caseLotPrice
        let salePrice

        const priceList = item.productItem?.relationships?.productitem_price?.data

        if (priceList?.length) {
          // filtered and sorted by quantity
          const newPriceList = priceList
            .sort((a, b) => a.quantity_breakpoint - b.quantity_breakpoint) // sort priceList by quantity breakpoint
            .filter(e => item.quantity >= e.attributes?.quantity_breakpoint) // fliter priceList by quantity_breakpoint for selected quantity

          // get price data for selected qty
          const priceObj = newPriceList.at(-1)

          const discountedPrice = +priceObj?.attributes?.discounted_price
          const price = +priceObj?.attributes?.price
          // check if discountedPrice is less than price for check if sale
          if (price > discountedPrice) {
            salePrice = +priceObj?.attributes?.discounted_price
          }

          // check if more than one price exits for Case Lot discount
          if (newPriceList.length > 1) {
            caseLotPrice = priceObj?.attributes?.price
          }
        }

        return {
          ...item,
          discount: {
            salePrice,
            caseLotPrice
          }
        }
      })
    },

    getCartTotalPrice (state) {
      return this.getCartItems.reduce((total, item) => {
        let itemPrice = item.price

        if (item.discount?.salePrice) {
          itemPrice = item.discount?.salePrice
        } else if (item.discount?.caseLotPrice) {
          itemPrice = item.discount?.caseLotPrice
        }

        const itemTotal = itemPrice ? itemPrice * item.quantity : 0
        return total + itemTotal
      }, 0)
    },

    qtyTotal (state) {
      return state.cart.reduce((total, item) => {
        return total + item.quantity
      }, 0)
    }
  },

  actions: {
    addToCart ({ productItem, quantity }, simpleimage) {
      // find product item in cart
      const cartItem = this.cart.find(e => e.productitemID === productItem.id)

      const qty = +quantity >= 1 ? +quantity : 1

      if (cartItem) {
        cartItem.quantity = cartItem.quantity + qty
      } else {
        const priceList = productItem?.relationships?.productitem_price?.data
        const price = priceList?.find(e => e.attributes?.quantity_breakpoint === 1)?.attributes?.price

        this.cart.push({
          productitemID: productItem.id,
          title: productItem.attributes.saleable_name,
          productID: productItem.attributes.productID,
          price,
          quantity: qty,
          productItem,
          simpleimage: _isArray(simpleimage) && simpleimage.length ? simpleimage[0] : simpleimage?.attributes ? simpleimage : null // image data
        })
      }
    },

    removeFromCart (cartItem) {
      this.cart = this.cart.filter(item => {
        if (item.productitemID !== cartItem.productitemID) return true
        return false
      })
    },

    setCart (payload) {
      this.cart = payload
    },

    clearCart () {
      this.cart = []
    },

    addSimpleImage (productitemID, simpleimage) {
      // find product item in cart
      const cartItem = this.cart.find(e => e.productitemID === productitemID)

      if (cartItem) {
        cartItem.simpleimage = _isArray(simpleimage) && simpleimage.length ? simpleimage[0] : simpleimage?.attributes ? simpleimage : null
      }
    }
  }
})
