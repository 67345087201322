import AxiosClient from '@/repositories/AxiosClient'
const qs = require('qs')

const request = AxiosClient.createRequest()
const apiKey = process.env.VUE_APP_API_KEY

export default {

  login (username, password) {
    return request.post('/login',
      qs.stringify({ username, password }),
      {
        params: {
          apikey: apiKey
        }
      }
    )
  },

  getUser () {
    return request.get('/me')
  },

  passwordResetRequest (email) {
    return request.post('/passwordresetrequest',
      qs.stringify({ email }),
      {
        params: {
          apikey: apiKey
        }
      }
    )
  },

  passwordResetHashValidation (email, hash) {
    return request.post('/passwordresethashvalidation',
      qs.stringify({ email, hash }),
      {
        params: {
          apikey: apiKey
        }
      }
    )
  },

  passwordReset (email, hash, password) {
    return request.post('/passwordreset',
      qs.stringify({ email, hash, password }),
      {
        params: {
          apikey: apiKey
        }
      }
    )
  }

}
