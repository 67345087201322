import { defineStore } from 'pinia'

export const useCategoryStore = defineStore('category', {
  state: () => {
    return {
      CurrentCategory: null
    }
  },

  getters: {
    getCurrentCategory: state => state.CurrentCategory
  },

  actions: {
    setCurrentCategory (category) {
      this.CurrentCategory = category
    }
  }
})
