import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import router from '@/router'

const domain = process.env.VUE_APP_REPOSITORY_URL

export default {

  createRequest () {
    const instance = axios.create({ baseURL: `${domain}` })

    instance.interceptors.request.use((config) => {
      const authStore = useAuthStore()
      const token = authStore.getAuthAccessToken

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })

    instance.interceptors.response.use((response) => {
      return response
    }, (error) => {
      // Logout if response code is 401 or 403
      if ([401, 403].includes(error.response.status)) {
        const authStore = useAuthStore()
        return authStore.AUTH_LOGOUT().then(() => {
          return router.push('/login')
        })
      }
    })

    return instance
  }

}
