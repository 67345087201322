<template>
  <div>
    <header class="ltn__header-area ltn__header-3">

      <!-- ltn__header-middle-area start -->
      <div class="ltn__header-middle-area ltn__secondary-bg">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="site-logo">
                <router-link to="/">
                  <img class="img-fluid" src="/img/logo/logo-black.webp" alt="Logo" width="175">
                </router-link>
              </div>
            </div>

            <div class="flex-grow-1 px-4 d-none d-lg-block">
              <AppSearchForm />
            </div>

            <div>
              <!-- header-options -->
              <div class="ltn__header-options">
                <ul>
                  <li>
                    <!-- user-menu -->
                    <div class="ltn__drop-menu user-menu">
                      <ul>
                        <li>
                          <a href="#"><i class="fa-regular fa-user" /></a>
                          <ul>
                            <li>
                              <router-link to="/account">
                                <i class="fa-solid fa-user" /> My Account
                              </router-link>
                            </li>
                            <li><a role="button" @click="logout"><i class="fa-solid fa-arrow-right-from-bracket" /> Logout</a></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <!-- mini-cart 2 -->
                    <div class="mini-cart-icon mini-cart-icon-2">
                      <router-link to="/shop/cart" class="ltn__utilize-toggle">
                        <span class="mini-cart-icon">
                          <i class="fa-regular fa-cart-shopping" />
                          <sup v-if="cartStore.getCartItems">{{ cartItemsCount }}</sup>
                        </span>
                        <h6><span>Your Cart</span> <span>{{ $filters.formatMoney(cartStore.getCartTotalPrice) }}</span></h6>
                      </router-link>
                    </div>
                  </li>
                  <li class="d-inline-block d-md-none">
                    <a @click="showMobileMenu = !showMobileMenu">
                      <i class="fa-solid fa-bars fs-5 text-white" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ltn__header-middle-area end -->

      <!-- header-bottom-area start -->
      <div class="header-bottom-area ltn__border-top shadow-sm d-md-block" :class="{'d-none': !showMobileMenu}">
        <div class="container">
          <div class="row">
            <div class="col header-menu-column justify-content-center">
              <div class="header-menu header-menu-2">
                <nav>
                  <div class="ltn__main-menu">
                    <ul>

                      <li v-for="(item, index) in menuItems" :key="index">
                        <router-link :to="item.to">
                          <span class="d-block">
                            {{ item.name }}
                          </span>
                        </router-link>
                      </li>

                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- header-bottom-area end -->

      <!-- search for mobile -->
      <div class="header-mobile-search">
        <AppSearchForm />
      </div>
    </header>

  </div>
</template>

<script>
import AppSearchForm from '@/components/app/AppSearchForm'
import { useCartStore } from '@/stores/cart'
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'

export default {
  name: 'TheHeader',
  components: {
    AppSearchForm
  },
  setup () {
    const cartStore = useCartStore()
    const authStore = useAuthStore()
    const router = useRouter()

    const cartItemsCount = computed(() => {
      return cartStore.cart?.length > 99 ? '99+' : cartStore.cart?.length
    })

    const logout = async () => {
      await authStore.AUTH_LOGOUT()
      router.push('/login')
    }

    return {
      cartStore,
      cartItemsCount,
      logout
    }
  },
  data () {
    return {
      menuItems: [
        {
          name: 'Dashboard',
          to: '/',
          icon: 'home'
        },
        {
          name: 'Browse Products',
          to: '/browse',
          icon: 'magnifying-glass'
        },
        {
          name: 'On Sale',
          to: '/on-sale',
          icon: 'percent'
        },
        {
          name: 'My Order',
          to: '/shop/cart',
          icon: 'file-alt'
        },
        {
          name: 'My Prescriptions',
          to: '/prescriptions',
          icon: 'prescription'
        },
        {
          name: 'My Protocols',
          to: '/protocols',
          icon: 'clipboard-list-check'
        },
        {
          name: 'Favorites',
          to: '/favorites',
          icon: 'heart'
        },
        {
          name: 'Order History',
          to: '/orders',
          icon: 'clock-rotate-left'
        },
        {
          name: 'Saved Orders',
          to: '/saved-orders',
          icon: 'circle-check'
        },
        {
          name: 'Manage Account',
          to: '/account',
          icon: 'user'
        }
      ],
      showMobileMenu: false
    }
  }
}
</script>

<style lang="scss">
.ltn__header-area {
  z-index: 999;
}

.ltn__header-3 .ltn__header-middle-area {
    padding: 15px 0;
}

.ltn__main-menu > ul > li > a {
  color: var(--ltn__heading-color);
  font-size: 14px;
  padding: 17px 10px;

  &:hover, &.router-link-exact-active {
    color: var(--ltn__secondary-color);
  }
}

.ltn__header-options, .ltn__drop-menu {
  & > ul > li > a {
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: #fff;
    }
  }
}

.ltn__drop-menu > ul > li:hover > a {
    color: #fff;
}

.mini-cart-icon-2 {
  a {
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: #fff;
    }

    .mini-cart-icon {
      i {
        color: rgba(255, 255, 255, 0.8);
      }

      sup {
        background-color: var(--bs-gray-700);
      }
    }
  }
}
</style>
