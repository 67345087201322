import AuthRepository from '@/repositories/auth/AuthRepository'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      userEmail: useStorage('userEmail', null),
      accessToken: useStorage('accessToken', null),
      user: useStorage('user', {})
    }
  },

  getters: {
    getAuthEmail: state => state.userEmail,
    getAuthAccessToken: state => state.accessToken,
    getAuthUser: state => state.user
  },

  actions: {
    AUTH_SET_EMAIL (email) {
      this.userEmail = email
    },

    AUTH_SET_ACCESS_TOKEN (accessToken) {
      this.accessToken = accessToken
    },

    AUTH_SET_USER (user) {
      this.user = user
    },

    async AUTH_LOGIN ({ email, password }) {
      try {
        const response = await AuthRepository.login(email, password)

        if (response && response.status === 200) {
          const data = response.data

          this.AUTH_SET_EMAIL(email)
          this.AUTH_SET_ACCESS_TOKEN(data.authentication.token)

          return Promise.resolve(data)
        } else {
          return Promise.reject(new Error('Login failed'))
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async AUTH_TOKEN_LOGIN (token) {
      this.AUTH_SET_ACCESS_TOKEN(token)

      try {
        const response = await AuthRepository.getUser()

        if (response && response.status === 200 && response.data) {
          const data = response.data

          this.AUTH_SET_EMAIL(data?.attributes?.email)
          this.AUTH_SET_USER(data?.attributes)

          return Promise.resolve(data)
        } else {
          this.AUTH_SET_ACCESS_TOKEN(null)
          return Promise.reject(new Error('Login failed'))
        }
      } catch (error) {
        this.AUTH_SET_ACCESS_TOKEN(null)
        return Promise.reject(error)
      }
    },

    async AUTH_CHECK_LOGIN () {
      if (!this.accessToken) {
        return Promise.reject(new Error('Not Logged In'))
      } else {
        AuthRepository.getUser().then((response) => {
          this.AUTH_SET_USER(response?.data?.data?.attributes)
        })
        return Promise.resolve()
      }
    },

    AUTH_LOGOUT () {
      this.AUTH_SET_EMAIL(null)
      this.AUTH_SET_ACCESS_TOKEN(null)
      this.AUTH_SET_USER(null)
      return Promise.resolve()
    }
  }
})
