import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import BootstrapVue3, { BToastPlugin } from 'bootstrap-vue-3'
import Maska from 'maska'
import VueDOMPurifyHTML from 'vue-dompurify-html'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(BootstrapVue3)
app.use(VueDOMPurifyHTML)
app.use(BToastPlugin)

const formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' })
app.config.globalProperties.$filters = {
  formatMoney (value) {
    return value !== null ? formatter.format(parseFloat(value) || 0) : null
  }
}

app.use(Maska)
app.directive('maska', Maska.maska)

app.mount('#app')
