<template>
  <div class="header-search-2">
    <form @submit.prevent="search">
      <input
        v-model="searchInput"
        type="text"
        name="search"
        autocomplete="off"
        placeholder="Search here..."
      >

      <button type="submit">
        <span><i class="fa fa-magnifying-glass" /></span>
      </button>
    </form>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'AppSearchForm',
  setup () {
    const router = useRouter()
    const route = useRoute()

    const searchInput = ref()

    const search = () => {
      router.push({
        path: '/search',
        query: {
          search: searchInput.value
        }
      })
    }

    const isSearchRoute = computed(() => {
      return route.matched.some(({ path }) => path === '/search')
    })

    // fill search input on page load
    if (isSearchRoute.value && route.query.search) {
      searchInput.value = route.query.search
    }

    watch(() => isSearchRoute.value, () => {
      // clear input if not search screen
      if (!isSearchRoute.value) {
        searchInput.value = null
      }
    })

    return {
      searchInput,
      search
    }
  }
}
</script>
