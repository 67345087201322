<template>
  <slot name="button">
    <button v-if="!hideButton" class="btn" :class="buttonClass" @click="open()">
      <slot name="button-content">
        {{ buttonTitle }}
      </slot>
    </button>
  </slot>

  <Teleport v-if="!(lazy && isHidden)" to="body">
    <div
      :id="modalId"
      ref="modalRef"
      class="app-model modal fade"
      :data-bs-backdrop="noClose ? 'static' : true"
      :data-bs-keyboard="!noClose"
      tabindex="-1"
    >
      <div class="modal-dialog" :class="size && `modal-${size}`">
        <div class="modal-content">

          <div class="modal-header">
            <slot name="header">
              <h5 v-if="title || $slots.title" class="modal-title">
                <slot name="title">
                  {{ title }}
                </slot>
              </h5>

              <button v-if="!noClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </slot>
          </div>

          <div class="modal-body" :class="bodyClass">
            <slot :modal-data="modalData">
              <p>Modal body text goes here.</p>
            </slot>
          </div>

          <div v-if="!hideFooter" class="modal-footer" :class="footerClass">
            <slot name="footer">
              <button type="button" class="btn theme-btn-1 btn-effect-1 py-2 px-3" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn btn-primary btn-effect-1 py-2 px-3" @click="close()">
                Save changes
              </button>
            </slot>
          </div>

        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal } from 'bootstrap'
import { computed, nextTick, onMounted, ref } from 'vue'
import _uniqueId from 'lodash/uniqueId'

export default {
  name: 'AppModal',
  props: {
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },

    buttonTitle: {
      type: String,
      default: 'Open modal'
    },
    buttonClass: {
      type: [Array, Object, String],
      default () {
        return null
      }
    },
    hideButton: {
      type: Boolean,
      default: false
    },

    bodyClass: {
      type: [Array, Object, String],
      default () {
        return null
      }
    },

    size: {
      type: String,
      default: null
    },

    footerClass: {
      type: [Array, Object, String],
      default () {
        return null
      }
    },

    hideFooter: {
      type: Boolean,
      default: false
    },

    lazy: {
      type: Boolean,
      default: true
    },

    // prevent closing modal
    noClose: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const modal = ref(null)
    const modalRef = ref(null)

    const isHidden = ref(true)

    const modalData = ref(null)

    const open = async (data) => {
      if (props.lazy && isHidden) {
        isHidden.value = false

        await nextTick()
        modal.value = new Modal(modalRef.value)
      }

      modalData.value = data
      modal.value.show()
    }

    const close = () => {
      modal.value.hide()
    }

    const modalId = computed(() => {
      return _uniqueId(props.id || 'modal-')
    })

    onMounted(() => {
      if (!(props.lazy && isHidden)) {
        modal.value = new Modal(modalRef.value)
      }
    })

    return {
      open,
      close,
      modalId,
      modalRef,
      modalData,
      isHidden
    }
  }
}
</script>
